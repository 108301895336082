import React from 'react'
import NewHero from './NewHero'
import newImage from "./newPanner.png"
import { Carousel } from 'react-bootstrap';

const Index = () => {
  return (
    <Carousel controls={false} interval={9000} pause={false} className="landing-slider">
      {/* First slide with image */}
      <Carousel.Item>
        <img src={newImage} className="w-100 img-fluid" alt="Hero" />
      </Carousel.Item>

      {/* Second slide with custom NewHero component */}
      <Carousel.Item>
        <NewHero />
      </Carousel.Item>
    </Carousel>
  );
};

export default Index;
