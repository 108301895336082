import React, { useEffect, useRef, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import * as THREE from "three";
import VANILLA_GLOBE from "vanta/dist/vanta.globe.min";
import AOS from "aos";
import './NewLandingHero.css'
import { Container, Row, Carousel, Col } from 'react-bootstrap';
import { FaUsers, FaAward } from "react-icons/fa";
import { HiClipboardDocumentList } from "react-icons/hi2";
import { MdSupportAgent } from "react-icons/md";

const LandingHero = React.memo(({ onExploreClick }) => {
    const navigate = useNavigate();
    const vantaRef = useRef(null);
    const [hasScrolled, setHasScrolled] = useState(false);

    useEffect(() => {
        const vantaEffect = VANILLA_GLOBE({
            el: vantaRef.current,
            THREE: THREE,
            mouseControls: true,
            touchControls: true,
            gyroControls: false,
            minHeight: 200.0,
            minWidth: 200.0,
            scale: 1.0,
            scaleMobile: 1.0,
            color: 0x7a1cac,
            color2: 0x7a1cac,
            color3: 0x7a1cac,
            backgroundColor: 0xfcfcfc,
        });

        return () => {
            if (vantaEffect) vantaEffect.destroy();
        };
    }, []);

    useEffect(() => {
        const handleScroll = () => {
            if (!hasScrolled) {
                setHasScrolled(true);
            }
        };

        window.addEventListener("scroll", handleScroll);

        return () => {
            window.removeEventListener("scroll", handleScroll);
        };
    }, [hasScrolled]);

    return (
        <div>
            <div ref={vantaRef} >
                <div className={`hero-section ${hasScrolled ? "zoom-in" : ""}`}>
                    <Container>
                        <Row>
                            <Carousel controls={false} interval={3000} pause={false} className='landing-slider'>
                                <Carousel.Item>
                                    <div className="carousel-content">
                                        <div className="hero-header">
                                            <h2 className="hero-header-subHeading">
                                            Stay Ahead In Markets With {" "}
                                            </h2>
                                            <h1 className="start-typing hero-header-heading heading ">
                                                KAMA CAPITAL
                                            </h1>
                                        </div>
                                        <div className='mt-3'>
                                            <button className="hero-start-btn btn-style" onClick={() => navigate('/en/contact')}>
                                            Get started →
                                        </button>
                                        <button className="hero-explore-btn btn-style ms-lg-5 ms-3" onClick={onExploreClick}>
                                            Explore →
                                        </button>
                                        </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="carousel-content">
                                        <div className="hero-header">
                                            <h2 className="hero-header-subHeading">
                                                Navigating Market With {" "}
                                            </h2>
                                            <h1 className="start-typing hero-header-heading heading">
                                                Precision
                                            </h1>
                                        </div>
                                       <div className='mt-3'>
                                       <button className="hero-start-btn btn-style" onClick={() => navigate('/en/contact')}>
                                            Get started →
                                        </button>
                                        <button className="hero-explore-btn btn-style ms-lg-5 ms-3" onClick={onExploreClick}>
                                            Explore →
                                        </button>
                                       </div>
                                    </div>
                                </Carousel.Item>
                                <Carousel.Item>
                                    <div className="carousel-content">
                                        <div className="hero-header">
                                            <h2 className="hero-header-subHeading">
                                                Collaborate For Global{" "}
                                            </h2>
                                            <h1 className="start-typing hero-header-heading heading">
                                                Market Excellence
                                            </h1>
                                        </div>
                                        <div className='mt-3'>
                                        <button className="hero-start-btn btn-style" onClick={() => navigate('/en/contact')}>
                                            Get started →
                                        </button>
                                        <button className="hero-explore-btn btn-style ms-lg-5 ms-3" onClick={onExploreClick}>
                                            Explore →
                                        </button>
                                        </div>
                                    </div>
                                </Carousel.Item>
                            </Carousel>
                        </Row>
                    </Container>
                </div>
            </div>
        </div>
    )
})

export default React.memo(LandingHero);
